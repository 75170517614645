<template>
    <div
        id="app"
        class="b-application b-nal-layout"
        :class="langClass"
    >

        <notifications ref="notifications"></notifications>

        <ezekia-nav
            v-if="showNavFooter"
            class="b-application__nav"
        ></ezekia-nav>

        <div class="b-application__main">
            <div class="b-application__content">
                <router-view></router-view> <!-- Do not touch -->
            </div>
        </div>

        <ezekia-footer v-if="showNavFooter"></ezekia-footer>

        <cookie-banner colorClass="u-primary-color"></cookie-banner>

        <leave-impersonate v-if="isImpersonating || isNovaImpersonating"></leave-impersonate>
    </div>
</template>

<script>

import CookieBanner from '@js/vue-components/layout/cookie-banner.vue';
import Notifications from '@js/vue-components/general/notification/notifications.vue';
import EzekiaFooter from './layout/ezekia-footer.vue';
import EzekiaNav from './layout/ezekia-nav.vue';
import LeaveImpersonate from '@js/nal/vue-components/layout/leave-impersonate.vue';

export default {
    name: 'Application',
    components: {
        'cookie-banner': CookieBanner,
        'notifications': Notifications,
        'ezekia-footer': EzekiaFooter,
        'ezekia-nav': EzekiaNav,
        'leave-impersonate': LeaveImpersonate,
    },
    computed: {
        showNavFooter() {
            return this.$route
                && !this.$route.path.includes('register')
                && !this.$route.path.includes('login')
                && !this.$route.path.includes('recover')
                && !this.$route.path.includes('password')
                && !this.$route.path.includes('reset');
        },
        langClass() {
            return `lang-${window.nal.locale}`;
        },
        isImpersonating() {
            return window.nal.user?.isImpersonating;
        },
        isNovaImpersonating() {
            return window.nal.user?.isNovaImpersonating;
        },
    },
};

</script>

<style lang="scss">

@import "@sass/mixins";
@import "@sass/variables";

html, body {
    min-height: 100%;
    position: relative;
}

body {
    background-color: #f4f4f4;
}

.b-application {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    min-height: 100%;
    position: relative;

    &--full {
        padding: 0;
    }

    &__nav {
        z-index: z('over');
    }

    &__main {
        flex: 1 0 auto;
        width: 100%;

        &--fixed {
            margin-top: 66px;
        }
    }

    .u-primary-color__bg {
        background-color: rgba(58, 81, 153, 0.86);
    }

    .basic-button {
        color: #fff;
        display: inline-flex;
        font-size: 13px;
        font-weight: 400;
        padding: 5px 14px;
        text-transform: uppercase;
    }

    .lowercase-button {
        color: #fff;
        display: inline-flex;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 14px;
    }

    .border-button {
        border: 1px solid;
        display: inline-flex;
        font-weight: 400;
        padding: 3px 8px;
    }

    &__footer {
        margin: 0 -40px;
        z-index: z('over');

        @media (max-width: 576px) {
            margin: 0 -20px;
        }

        @media (max-width: 450px) {
            margin: 0 -10px;
        }
    }
}
</style>
